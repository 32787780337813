import { useEffect, useState } from "react";

const Accordion = ({
  title = "",
  description = "",
  closeAll = () => true,
  forceCloseAccordions = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!forceCloseAccordions) {
      return;
    }
    open && setOpen(!open);
  }, [forceCloseAccordions, setOpen, open]);

  return (
    <div
      onClick={() => {
        closeAll();
        setTimeout(() => {
          setOpen(!open);
        }, 10);
      }}
      className={"accordion"}
    >
      <div>
        <h5 className={"tag-h5 color-black"}>{title}</h5>
        {open && (
          <p className={"body_regular color-light-grey accordion-description"}>
            {description}
          </p>
        )}
      </div>
      <i
        className={`${open ? "icon-minus" : "icon-plus"}`}
        style={{ fontSize: "20px", color: "black" }}
      />
    </div>
  );
};

export default Accordion;
