import './App.css';
import Lottie from 'react-lottie';

import Composizione_landing_app from './animations/Composizione_landing_app.json';
import Telefono_Landing from './animations/Telefono_Landing.json';
import Mockup_Positive from './animations/Mockup_Positive.json';
import Accordion from './components/Accordion';
import { useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { InputForm } from './components/inputFields';
import { submitForm } from './service';
import ErrorToast from './components/ErrorToast';
import SuccessToast from './components/SuccessToast';


const App = () => {
  const [forceCloseAccordions, setForceCloseAccordions] = useState(false);
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = {
      submittedAt: Date.now().toString(),
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: lastName,
        },
      ],
    };
    setIsSubmitting(true);
    submitForm(
      payload,
      () => {
        setShowSuccessToast(true);
        resetFormValues();
        setIsSubmitting(false);
      },
      () => {
        setShowErrorToast(true);
        setIsSubmitting(false);
      }
    );
  };

  const resetFormValues = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setIsSubmitting(true);
  };

  return (
    <div className="App">
      <div className="parent full-height v-align-center ">
        <div id="page-1-div-1">
          <a
            itemProp="url"
            href="https://talentgarden.org/en"
            target="_blank"
            rel="noreferrer"
            className={'logo-link'}
          >
            <img src="images/logo.png" style={{ height: '60px' }} alt="" />
          </a>

          <h1 className="tag-h1 lubalin color-black main-title" style={{}}>
            {'Enjoy the\nnew app.'}
          </h1>
          <p
            className="tag-body_regular color-light-grey main-description"
            style={{ textAlign: 'left' }}
          >
            Say goodbye to TAG People and welcome the new Talent Garden app. We
            worked hard in the last few months to give our Taggers a new version
            of the app, what you have to do is just to update it and enjoy the
            new features, or download it if you don’t have it yet.
          </p>
          <div style={{ textAlign: 'left' }}>
            <a
              className="App-link"
              href="https://apps.apple.com/us/app/talent-garden/id1406741975"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={'images/banner_ios.png'}
                alt="Download iOS app"
                style={{ height: '48px' }}
              />
            </a>
            <a
              className="App-link"
              href="https://play.google.com/store/apps/details?id=com.tagapp&hl=it&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={'images/banner_android.png'}
                alt="Download android app"
                style={{ height: '48px', marginLeft: '10px' }}
              />
            </a>
          </div>
        </div>
        <div
          id="page-1-div-2"
          style={{ textAlign: 'center' }}
          className="main_image"
        >
          {/* <img
            src="images/Frame_1.png"
            style={{}}
            className="main_image"
            alt=""
          /> */}
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Composizione_landing_app,
              // rendererSettings: {
              //   preserveAspectRatio: "xMidYMid slice",
              // },
            }}
            height={'100%'}
            width={'100%'}
            isStopped={false}
            isPaused={false}
          />
        </div>
      </div>
      {/* pagina 2 */}
      <div className="bg-peach" style={{}} id="page-2-parent">
        <div className={'parent-min-gap v-align-center '}>
          <h2 className="tag-h2 lubalin color-black" style={{}}>
            {'Main features'}
          </h2>
        </div>
        <div
          className={'parent-min-gap v-align-center '}
          style={{
            alignItems: 'flex-start',
          }}
        >
          <div id="page-2-div-1">
            <div className={'main-card'} style={{}}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: Telefono_Landing,
                  // rendererSettings: {
                  //   preserveAspectRatio: "xMidYMid slice",
                  // },
                }}
                height={160}
                width={'100%'}
                isStopped={false}
                isPaused={false}
              />
              <p className={'tag-label_bold color-orange'}>BOOK A ROOM</p>
              <h3 className="tag-h3">
                {'Book any meeting room in any campus in the blink of an eye'}
              </h3>
              <p className="tag-body_regular color-light-grey text-pre-line">
                {
                  'Do you need a private space?\nWith the new Talent Garden app you\nwill be able to book a room in a few seconds, managing your\nmeetings seamlessly, even the ones you werent expecting.'
                }
              </p>
            </div>
          </div>
          <div id="page-2-div-2">
            <div
              className={'main-card'}
              style={{
                contain: 'content',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {/* <div className={'cooming-soon__container'}>
                <p className={'tag-label_small'}>COMING SOON</p>
              </div> */}
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: Mockup_Positive,
                  // rendererSettings: {
                  //   preserveAspectRatio: "xMidYMid slice",
                  // },
                }}
                height={160}
                width={'100%'}
                isStopped={false}
                isPaused={false}
              />
              <p className={'tag-label_bold color-orange'}>ACCESS</p>
              <h3 className="tag-h3">
                {
                  'Forget the badge,  now you can unlock any door with your smartphone'
                }
              </h3>
              <p className="tag-body_regular color-light-grey text-pre-line">
                {
                  'How many times did you lose your badge? Forget this discomfort: with the new app, you will be able to unlock (almost) any door of the campus using your phone and a bluetooth connection. '
                }
              </p>
            </div>
          </div>
        </div>
        <div className={'parent-min-gap v-align-center'}>
          <div id="page-2-div-3" style={{}}>
            <p className={'tag-label_bold color-orange'}>SUPPORT</p>
            <h1 className="tag-h1 " style={{ paddingRight: '15px' }}>
              {'Ask for help to our staff. We will be happy to help you.'}
            </h1>
            <p
              className="tag-body_regular color-light-grey"
              style={{ textAlign: 'left' }}
            >
              Inside the app, you will have a direct access to contact our
              staff. In case of any need, you’ll only have to pick your phone
              and drop us a line.
            </p>
          </div>
          <div id="page-2-div-4" style={{ textAlign: 'center' }}>
            <img
              src="images/Group_994.png"
              style={{ height: '274px' }}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* pagina 3 */}
      <div
        className="parent-1col v-align-center"
        style={{
          paddingTop: '160px',
          // paddingBottom: "160px",
          textAlign: 'center',
        }}
        id="page-3-parent"
      >
        <div id="page-3-div-1" className="bg-orange" style={{}}>
          <Splide
            options={{
              rewind: true,
              width: '85%',
              gap: '1rem',
              pagination: false,
              classes: {
                // Add classes for arrows.
                arrows: 'splide__arrows your-class-arrows',
                arrow: 'splide__arrow tag-arrow',
                prev: 'splide__arrow--prev tag-arrow--prev',
                next: 'splide__arrow--next tag-arrow--next',

                // Add classes for pagination.
                pagination: 'splide__pagination your-class-pagination', // container
                page: 'splide__pagination__page your-class-page', // each button
              },
              arrowPath:
                'M22.4,12l-0.7,0.7l-9,9l-1.4-1.4l7.3-7.3H2v-2h16.6l-7.3-7.3l1.4-1.4l9,9L22.4,12z',
            }}
          >
            <SplideSlide>
              <img
                src="images/slider1.png"
                style={{}}
                alt=""
                className={'slider-image'}
              />
              <h3
                className="tag-h3 color-white"
                style={{ textAlign: 'center' }}
              >
                {'Flexible by design'}
              </h3>
              <p
                className="tag-body_regular color-white slider-description"
                style={{ textAlign: 'center' }}
              >
                {
                  'As a mobile first experience, the homepage follows your needs,\nto give you the right functionalities when you need them.'
                }
              </p>
            </SplideSlide>
            <SplideSlide>
              <img
                src="images/slider2.png"
                style={{}}
                alt=""
                className={'slider-image'}
              />

              <h3
                className="tag-h3 color-white"
                style={{ textAlign: 'center' }}
              >
                {'Unlock new possibilities'}
              </h3>
              <p
                className="tag-body_regular color-white slider-description"
                style={{ textAlign: 'center' }}
              >
                {
                  'Discover what every campus has to offer, and book the perfect room for every meeting.'
                }
              </p>
            </SplideSlide>
            <SplideSlide>
              <img
                src="images/slider3.png"
                style={{}}
                alt=""
                className={'slider-image'}
              />

              <h3
                className="tag-h3 color-white"
                style={{ textAlign: 'center' }}
              >
                {'Easier than Open Sesame'}
              </h3>
              <p
                className="tag-body_regular color-white slider-description"
                style={{ textAlign: 'center' }}
              >
                {
                  'Open a door has never been so easy: just get close to a door and wait for the magic to happen.'
                }
              </p>
            </SplideSlide>
            <SplideSlide>
              <img
                src="images/slider4.png"
                style={{}}
                alt=""
                className={'slider-image'}
              />

              <h3
                className="tag-h3 color-white"
                style={{ textAlign: 'center' }}
              >
                {'Make things happen. Faster.'}
              </h3>
              <p
                className="tag-body_regular color-white slider-description"
                style={{ textAlign: 'center' }}
              >
                {
                  'In the new app you will be able to book a room in a second: just push the button and get your space.'
                }
              </p>
            </SplideSlide>
          </Splide>
        </div>

        <div
          id="page-3-div-2"
          className={'child'}
          style={{ paddingTop: '160px', paddingBottom: '160px' }}
        >
          <h1
            className="tag-h1 lubalin color-black main-title"
            style={{ textAlign: 'center' }}
          >
            {'Update your\napp now.'}
          </h1>
          <h5
            className="tag-body_regular color-light-grey"
            style={{ textAlign: 'center' }}
          >
            If you don’t have the app yet, download it from the stores:
          </h5>
          <a
            className="App-link"
            href="https://apps.apple.com/us/app/talent-garden/id1406741975"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'images/banner_ios.png'}
              alt="Download iOS app"
              style={{ height: '48px' }}
            />
          </a>
          <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=com.tagapp&hl=it&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'images/banner_android.png'}
              alt="Download android app"
              style={{ height: '48px', marginLeft: '10px' }}
            />
          </a>
        </div>

        <div
          id="page-3-div-3"
          className={'child bg-light-grey'}
          style={{ paddingTop: '160px' }}
        >
          <form className="form_container" onSubmit={onSubmit}>
            <h1
              className="tag-h1 lubalin color-black "
              style={{ textAlign: 'center' }}
            >
              {'Are you curious?'}
            </h1>
            <h5
              className="tag-body_regular color-light-grey"
              style={{ textAlign: 'center' }}
            >
              Our app is only for Talent Garden members but if you are curious
              to try it first
              <br className="only_desktop" /> leave us your details here, we
              will contact you as soon as possible.
            </h5>
            <div className={'form-fullname'}>
              <InputForm
                value={firstName}
                onChangeText={(Text) => setFirstName(Text)}
                type={'text'}
                formName={'firstName'}
                isRequired={true}
                placeholder={'First Name *'}
                id={'firstName'}
                customClasses={'tag-pb-regular form-field '}
                noLabel={true}
                autocapitalize
              />
              <InputForm
                value={lastName}
                onChangeText={(Text) => setLastName(Text)}
                type={'text'}
                formName={'lastName'}
                isRequired={true}
                placeholder={'Last Name *'}
                id={'lastName'}
                customClasses={'tag-pb-regular form-field '}
                noLabel={true}
                autocapitalize
              />
            </div>
            <div className={'form-email'}>
              <InputForm
                value={email}
                onChangeText={(Text) => setEmail(Text)}
                type={'email'}
                formName={'email'}
                labelText={'Email'}
                isRequired={true}
                placeholder={'Email *'}
                id={'email'}
                customClasses={'tag-pb-regular form-field '}
                noLabel={true}
              />
            </div>
            <p
              className="tag-label_small color-light-grey"
              style={{ textAlign: 'center' }}
            >
              By continuing with this operation, I declare to accept the&nbsp;
              <a
                href="https://talentgarden.org/en/privacy-policy/"
                style={{ color: '#858788' }}
                target="_blank"
                rel="noreferrer"
              >
                <strong>Privacy Policy</strong>
              </a>
              &nbsp;of Talent Garden S.p.A.
            </p>
            <button
              type="submit"
              className={`tag-button tag-button__primary tag-button__primary--large tag-button__primary--orange  tag-margin-auto ${isSubmitting ? 'tag-button__primary--disabled' : null
                }`}
              disabled={isSubmitting}
            >
              Send
            </button>
            <div style={{ maxWidth: '50%', margin: '20px auto' }}>
              {showSuccessToast && (
                <SuccessToast
                  closeToast={() => setShowSuccessToast(false)}
                  showToast={showSuccessToast}
                />
              )}
              {showErrorToast && (
                <ErrorToast
                  closeToast={() => setShowErrorToast(false)}
                  showToast={showErrorToast}
                />
              )}
            </div>
          </form>
        </div>

        {/* FAQ */}
        <div
          id="page-3-div-2"
          style={{
            paddingTop: '160px',
            paddingBottom: '160px',
            textAlign: 'left',
          }}
          className={'bg-light-grey parent-1col v-align-center'}
        >
          <div style={{ width: '75%', margin: '0 auto' }} className={'child'}>
            <h2 className="tag-h2 lubalin color-black ">{'Faq'}</h2>
            <h5 className="tag-body_regular color-light-grey">
              {
                'Need help? Look no further! Discover here the most common troublehshooting topics:'
              }
            </h5>
            <Accordion
              title={'How can I get the new app?'}
              description={
                'The new app will replace TAG People, so what you need to do is just to update it,  or to download  the new one from the App Store or Google Play.'
              }
              forceCloseAccordions={forceCloseAccordions}
              closeAll={() => {
                setForceCloseAccordions(true);
                setTimeout(() => {
                  setForceCloseAccordions(false);
                }, 10);
              }}
            />
            <Accordion
              title={'Will I find my old reservations in the new app?'}
              description={
                'If you booked a room using the old app, the reservation will be valid and you will find it in the new app, as long as its date is before 19th July 2021. If you already booked a room after that date, the reservation will be deleted.'
              }
              forceCloseAccordions={forceCloseAccordions}
              closeAll={() => {
                setForceCloseAccordions(true);
                setTimeout(() => {
                  setForceCloseAccordions(false);
                }, 10);
              }}
            />
            <Accordion
              title={
                'Where can I find all the features I had before on the app?'
              }
              description={
                'In this first release everything that is not mandatory to survive has been deleted, here some examples: Job Posting, Deals and benefits, User Directory, Groups, Activities, Profiles, Messages, and many more unused features.'
              }
              forceCloseAccordions={forceCloseAccordions}
              closeAll={() => {
                setForceCloseAccordions(true);
                setTimeout(() => {
                  setForceCloseAccordions(false);
                }, 10);
              }}
            />
            <Accordion
              title={'How do I find out my APP User and Password? '}
              description={
                'You will be able to access the app with the unique ID you are using for TAG People, WiFi, and Printers. If you have any trouble logging in, you should be able to restore your password directly from the app.'
              }
              forceCloseAccordions={forceCloseAccordions}
              closeAll={() => {
                setForceCloseAccordions(true);
                setTimeout(() => {
                  setForceCloseAccordions(false);
                }, 10);
              }}
            />
          </div>
        </div>
      </div>

      <div className={'footer'}>
        <div className={'details'}>
          <a
            itemProp="url"
            href="https://talentgarden.org/en"
            target="_blank"
            rel="noreferrer"
            className={'logo-link'}
          >
            <img
              src="images/logo_white.png"
              style={{ height: '90px' }}
              alt=""
            />
          </a>
          <div className={'tag-info'}>
            <p className={'tag-label_bold'}>TALENT GARDEN S.P.A.</p>
            <p className={'tag-label_regular'}>
              {'Piazza Città di Lombardia n. 1 20124\nMilan,Italy '}
            </p>
          </div>
          <div className="social-share">
            <ul className="">
              <li>
                <a
                  href="https://www.facebook.com/talentgarden"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://334045-1026637-1-raikfcquaxqncofqfm.stackpathdns.com/wp-content/themes/tag/assets/img/icon/facebook-w.svg"
                    height="15"
                    alt="talent garden facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/talentgarden/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://334045-1026637-1-raikfcquaxqncofqfm.stackpathdns.com/wp-content/themes/tag/assets/img/icon/instagram-w.svg"
                    height="15"
                    alt="talent garden instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/talent-garden"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://334045-1026637-1-raikfcquaxqncofqfm.stackpathdns.com/wp-content/themes/tag/assets/img/icon/linkedin-w.svg"
                    height="15"
                    alt="talent garden linkedin"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/TalentGardenen"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://334045-1026637-1-raikfcquaxqncofqfm.stackpathdns.com/wp-content/themes/tag/assets/img/icon/twitter-w.svg"
                    height="15"
                    alt="talent garden twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/c/TalentGardenOfficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://334045-1026637-1-raikfcquaxqncofqfm.stackpathdns.com/wp-content/themes/tag/assets/img/icon/youtube-w.svg"
                    height="15"
                    alt="talent garden youtube"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={'disclaimer'}>
          <span>
            {`©${new Date().getFullYear()} Talent Garden S.p.A. - Nominal Capital €492.354 - REA MI-2099746 - P.IVA/VAT IT03340710981 | `}
          </span>
          <a
            href="https://talentgarden.org/en/privacy-policy/"
            className={'link '}
          >
            Privacy Policy&nbsp;
          </a>
          -
          <a
            href="https://talentgarden.org/en/cookie-policy/"
            className={'link '}
          >
            &nbsp;Cookie Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
