export const capitalize = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeAllFirstLetter = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  const strSplitted = str.split(" ");
  const capitalized = strSplitted?.map((str) => capitalize(str));

  return capitalized.join(" ");
};
