import { capitalizeAllFirstLetter } from "../../utils";

const InputForm = ({
  formName,
  labelText,
  isRequired = false,
  value = "",
  placeholder,
  type,
  onChangeText = () => true,
  id,
  customClasses = "",
  disabled = false,
  noLabel = false,
  autocapitalize = false,
}) => {
  return (
    <div
      className={`tag-input__container ${customClasses ? customClasses : null}`}
    >
      {!noLabel && (
        <label
          htmlFor={formName}
          className={`input__label ${
            isRequired ? "input__label--required" : ""
          }`}
          data-required={isRequired.toString()}
        >
          {labelText}
        </label>
      )}

      <input
        type={type}
        id={id}
        name={formName}
        placeholder={placeholder}
        className={`input__form ${autocapitalize ? "autocapitalize" : null}`}
        value={value}
        onChange={(e) => {
          if (autocapitalize) {
            return onChangeText(capitalizeAllFirstLetter(e?.target?.value));
          }
          return onChangeText(e?.target?.value);
        }}
        required={isRequired}
        disabled={disabled}
      />
    </div>
  );
};

export default InputForm;
