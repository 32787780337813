import axios from "axios";
const FORM_ID = "b841927f-a77c-43bf-8b0e-a7443eb67168";
const FORM_PORTAL_ID = "4808925";

export const submitForm = async (
  payload,
  successCallback = () => true,
  errorCallback = () => true
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${FORM_PORTAL_ID}/${FORM_ID}`;
  const response = await axios.post(url, JSON.stringify(payload), {
    headers: headers,
  });
  if (response?.status === 200 || response?.status === 201) {
    // success
    successCallback(response?.data);
  } else {
    // error
    errorCallback();
  }
};
